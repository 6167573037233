export default {
  siteTitle: 'Aseem Shrey',
  siteAuthor: 'Aseem Shrey',
  siteUrl: 'https://www.aseemshrey.in',
  siteLogo: '/logo.png',
  description:
    'Security engineer, Teacher and Creator. This is my online abode.',
  userTwitter: 'aseemshrey',
  newsletter: 'https://aseemshrey.substack.com/',
  social: {
    twitter: 'https://twitter.com/Aseemshrey',
    github: 'https://github.com/LuD1161',
  },
  GATSBY_DISQUS_NAME: 'aseemshrey',
}
